<template>
	<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
</template>

<script>
import AppBoxTheme from "@/components/ui/AppBoxTheme";

export default {
	name: "UserWorkPage",

	data() {
		return {
			options: [
				{
					title: "app.tools.standard_mind_map.title",
					icon: "mindmap",
					route: {
						name: "StandardMapWork",
					},
				},
				// {
				// 	title: "app.tools.wizard_mind_map.title",
				// 	icon: "step",
				// 	route: {
				// 		name: "WizardMapWork",
				// 	},
				// },
				{
					title: "app.question_board",
					icon: "corkboard",
					route: {
						name: "QuestionBoardWork",
					},
				},
				{
					title: "app.sources",
					icon: "compare",
					route: {
						name: "SourcesWork",
					},
				},
				{
					title: "app.questionnaires",
					icon: "questionnaire",
					route: {
						name: "QuestionnaireWork",
					},
				},
				{
					title: "app.research_paper.title",
					icon: "contract",
					route: {
						name: "ResearchPaperWork",
					},
				},
			],
		};
	},

	components: {
		AppBoxTheme,
	},

	methods: {
		async proceed(route) {
			try {
				this.$loader.start();

				if (!route) {
					return;
				}

				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
